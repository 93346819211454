const currYr = new Date().getFullYear();

export default {
  // Poe Bots (https://poe.com/edit_bot?bot=LU_IMCSP / https://poe.com/LU_IMCSP)
  separatePoeBotClientIds: [
    'c45a8af14', 'LU_IMCSP', 'c0e83a709', 'PolyU_LMS', 'LU_ECON', 'PolyU_ENGL', 'LU_HIST',
    'caeb5730e',
    //'c8cfe95c9', // TODO: CBE bot
  ],

  // Teacher registration
  targetTeacherGroups: ["Principal", "Career Teacher", "5A", "5B", "5C", "5D", "5E", "6A", "6B", "6C", "6D", "6E"],
  coreSubjects: ['Chinese Language', 'English Language', 'Mathematics', 'Citizenship and Social Development'],

  formOptions: {
    sessionAppRoleField: "ab_sessionAppRole",

    // Secondary Students
    yearDSE: [currYr, currYr+1, currYr+2, currYr+3, currYr+4, currYr+5].map(yr => "" + yr),
    curriculums: ["HKDSE", "IGCSE", "IB", "Others"],
    electives: ["Chinese Literature", "English Literature", "Biology", "Chemistry", "Physics", "Science: Combined Science", "Science: Integrated Science",
                "Economics", "Business,Accounting and Financial Studies (BAFS)", "Chinese History", "History", "Geography", "Information and Communication Technology (ICT)",
                "Visual Arts", "Ethics and Religious Studies", "Health Management and Social Care", "Tourism and Hospitality Studies", "Design and Applied Technology", "Technology and Living",
                "Music", "Physical Education", "Mathematics Extended Part Module 1 (M1)", "Mathematics Extended Part Module 2 (M2)"].sort(),
    electivesIGCSE: ["English language", "Chinese language", "Mathematics", "Physics", "Chemistry", "Biology", "Computer Science",
                    "Further pure mathematics", "Business", "Geography", "Economics"],
    rankInForm: ["1 - 20", "21 - 50", "51 - 100", "100+", "Not disclosed by school"],

    // Teachers
    rolesInSchool: [
      "Principal", "Vice Principal", "Vice Principal (Career and Life Planning)", "Careers Teacher", "Subject Teacher - Chinese", "Subject Teacher - English", "Subject Teacher - Mathematics",
      "Subject Teacher - LS", "Subject Teacher - Physics", "Subject Teacher - Chemistry", "Subject Teacher - Biology", "Subject Teacher - BAFS",
      "Subject Teacher - Economics", "Subject Teacher - ICT", "Subject Teacher - Visual Arts", "Subject Teacher - Chinese History", "Subject Teacher - Geography", "Subject Teacher - E&RE",
    ],

    // University Students
    studentStatus: ['1. Local', '2. Mainland (Proficient in Cantonese)', '3. Mainland (Non-Cantonese speaking)', '4. International'],
    yearOfStudy: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Others'],
    gradYear: [currYr-1, currYr, currYr+1, currYr+2, currYr+3, currYr+4, currYr+5].map(yr => "" + yr),
    howToKnow: ['University', 'Friends', 'FDMT website', 'Facebook', 'Department email', 'WhatsApp from classmates', 'Other'],
  },

  // University Students
  cvSections: [
    'Education', 'Scholarship', 'Awards', 'Competition', 'Achievement', 'Overseas experiences', 'Study tour', 'Working experiences',
    'Internship experiences', 'Technical projects / Research experiences', 'ECA experiences', 'Community service experiences'
  ],

  // App-related
  versionCode: '1.0.0',
  websiteUrl: 'http://fdmt.hk/',

  primaryColor: '#01579b',
  logo: require('@/assets/logo.png'),
  logoWithText: require('@/assets/logo_rice.png'),
  logoWithTextWhite: require('@/assets/logo_rice_white.png'),
  logoWhite: require('@/assets/logo_white.png'),

  googleMapsApiKey: "AIzaSyBAfbeaXmrlCnW4dICmbieqlX-771Wz_G0",

  Firebase: {
    apiKey: "AIzaSyA3G_VzlBcpREyUF_UrVBQh-YM14t8JPXM",
    authDomain: "fdmt-atlas.firebaseapp.com",
    projectId: "fdmt-atlas",
    storageBucket: "fdmt-atlas.appspot.com",
    messagingSenderId: "784147710543",
    appId: "1:784147710543:web:77623618e6ef876e5ea03a",
    measurementId: "G-SPEHJMPQ79"
  },

  Stripe: {
    publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
  },

  OneSignal: {
    appId: 'cb736d94-d700-4216-9112-0decdc56d6e8',
  },

  HomePage: {
    banner: {
      slidingSpeed: 1000, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 1,
    }
  },

  publicPages: ["/terms-and-conditions", "/professions", "/sectors", "/segments", "/programs", "/apply", "/unsubscribe",
                "/events", "/confirm", "/withdraw", "/services", "/survey", "/sr", "/booking", "/appointment", "/p",
                "/ab3-pv", "/home"],
  //authPages: ["/forgot-password", "/login", "/n", "/register", "/r", "/s", "/u", "/t", "/sr"],
  //authPages: ["/forgot-password", "/login", "/n", "/register", "/regapply", "/regevent", "/r", "/s", "/u", "/t", "/sr", "/p"],
  authPages: ["/forgot-password", "/login", "/n", "/register", "/regapply", "/regevent", "/r", "/s", "/u", "/t", "/p"],
}